import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig, loginRequest } from "./authConfig";

const msalInstance = new PublicClientApplication(msalConfig);

export const signIn = () => {
  msalInstance.loginRedirect(loginRequest);
};

export const handleRedirect = async () => {
  try {
    const response = await msalInstance.handleRedirectPromise();
    console.log("response :: ", response)
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const acquireToken = async () => {
  const account = msalInstance.getAllAccounts()[0];
  try {
    const response = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account
    });
    return response.accessToken;
  } catch (error) {
    console.error(error);
  }
};
