// authConfig.js
export const msalConfig = {
  auth: {
    clientId: "f9a3f07f-adbc-4f2e-9840-4eabce1d03a5", // Replace with your Azure AD App Client ID
    authority: "https://login.microsoftonline.com/7dbe78a4-6ddd-4b48-a338-dd642823d5a4", // Replace with your tenant ID
    redirectUri: window.location.search, // Replace with your redirect URI
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false
    }
};

export const loginRequest = {
  scopes: ["openid", "profile", "email", "api://f9a3f07f-adbc-4f2e-9840-4eabce1d03a5/access_as_user"] // Replace with your API's scope
};
